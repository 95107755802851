
import Vue from 'vue';
import ScormIFrame from '@/domains/scorm/ScormIFrame.vue';
import * as ScormService from '@/services/api/lxp-scorm';
import { IScormContent } from '@/services/api/lxp-scorm/types';
import { ScormProgressStatus } from '@/domains/scorm';

export default Vue.extend({
  name: 'ScormExternal',
  components: {
    ScormIFrame,
  },
  data() {
    return {
      scorm: null as unknown as IScormContent,
    };
  },
  computed: {
    progressMeta(): string | null | undefined {
      return this.scorm?.progress?.progressMeta;
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const { content } = await ScormService.scormSessionGet({
          playerSessionId: Number(this.$route.params.playerSessionId),
        });

        this.scorm = content;
      } catch (e) {
        console.error(e);
      }
    },
    async update(data: string) {
      try {
        const request = {
          payload: { progressMeta: JSON.stringify(data) },
          playerSessionId: +this.$route.params.playerSessionId,
        };

        await ScormService.scormSessionUpdate(request);
      } catch (e) {
        console.error(e);
      }
    },
    broadcast(status: ScormProgressStatus): void {
      // Сделано по гайду:
      // https://diamantidis.github.io/2021/10/17/two-way-communication-between-flutter-webview-and-a-web-page
      if (status === ScormProgressStatus.COMPLETED || status === ScormProgressStatus.PASSED) {
        // @ts-ignore
        if (window.scormCompleted) {
          // @ts-ignore
          window.scormCompleted.postMessage(this.scorm.id);
        }
      }
    },
    async complete(): Promise<boolean> {
      try {
        await ScormService.scormSessionComplete({
          playerSessionId: +this.$route.params.playerSessionId,
        });

        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
});
